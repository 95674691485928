<template>
  <div>
    <el-card class="box-card" style="margin-top: -10px;">
       <el-row class="but">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick" style="width:430px;display:inline-block;">
                    <el-tab-pane label="执行中" name="first" class="tab"></el-tab-pane>
                    <el-tab-pane label="全部" name="second"></el-tab-pane>
                    <el-tab-pane label="未开始" name="third"></el-tab-pane>
                    <el-tab-pane label="已终止" name="four"></el-tab-pane>
        </el-tabs>
         <router-link to='/task-release'>
          <el-button type="primary"  style="float: right;margin-right:30px;">新建任务</el-button>
          </router-link>
          <el-button type="primary"  style="float: right;margin-right:20px;" @click="getDataList(1)">搜索</el-button>
           <el-input
            placeholder="请输入项目名称"
            v-model="taskName"
            style="width:500px;margin-right:50px;float: right;"
            clearable>
          </el-input>
      </el-row>

    </el-card>
     <el-card class="box-card" v-for="item in list" :key="item.taskId" >
                <div>  <span style="font-size:16px">{{item.taskName}}</span>
                <span  class="mini">{{item.taskStateName}}</span>
                </div>
                <div class="position">
                  <!-- 已终止 ---时则该按钮为不可点击状态。 -->
                   <el-button type="primary" @click="task(item.taskId)" :disabled="item.taskState==10032">任务结算</el-button>
                   <el-button type="primary" @click="management(item.taskId)">任务详情</el-button>
                </div>
                <div class="two">
                    起始日期 {{item.taskStartTime}} ~ {{item.taskEndTime}}
                    <span class="listspan">结算周期 {{item.settlementCycleName}}</span>
                    <span class="listspan">结算标准  {{item.price}} {{item.settlementStandardName}}</span>
                    <!-- <span class="listspan">所属行业 {{item.industryName}}</span> -->
                </div>
                <div style="margin-top:10px">
                    {{item.taskDesc}}
                </div>
                <div class="content-view">
                  <div class="hang">
                  <!-- <div v-for="item1 in pro"  :key="item1.index" class="hang"> -->
                        <el-progress v-if="item.signedNum" :percentage='item.signedNum/100*100'     :text-inside="false" :stroke-width="14" :format="setItemText(1,item.signedNum)" class="percalss" color="#169bd5"></el-progress>
                        <el-progress v-if="item.settledAmount" :percentage='item.settledAmount/100*100'  :text-inside="false" :stroke-width="14" :format="setItemText(2,item.settledAmount)" class="percalss" color="#0f6589"></el-progress>
                        <el-progress v-if="item.notSignedNum" :percentage="item.notSignedNum/100*100" :text-inside="false" :stroke-width="14" :format="setItemText(3,item.notSignedNum)" class="percalss" color="#fded3c"></el-progress>
                        <el-progress v-if="item.deductedServiceAmount" :percentage="item.deductedServiceAmount/1000*100" :text-inside="false" :stroke-width="14" :format="setItemText(4,item.deductedServiceAmount)" class="percalss" color="#3bbf2c"></el-progress>
                        <el-progress v-if="item.blacklistNum" :percentage="item.blacklistNum/100*100" :text-inside="false" :stroke-width="14" :format="setItemText(5,item.blacklistNum)" class="percalss" color="#e35858"></el-progress>
                        <el-progress v-if="item.settledTimes" :percentage="item.settledTimes/100*100" :text-inside="false" :stroke-width="14" :format="setItemText(6,item.settledTimes)" class="percalss" color="#ffbd9a"></el-progress>
                      <!-- <el-progress :percentage="item1.num/item1.mat*100" :text-inside="false" :stroke-width="14" :format="setItemText(item1)" class="percalss" :color="item1.customColor"></el-progress> -->
                  </div>
                </div>
                
    </el-card>
     <el-pagination
            background
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="currPage"
            :page-sizes="[10, 20,30, 50, 100]"
            :page-size="pageSize"
            :total="totalCount"
            class="text_center"
            layout="total,  prev, pager, next, sizes,jumper"
             style="text-align:right;margin-top:30px"
          ></el-pagination>
  </div>
</template>

<script>
import {merchantTaskPageList } from  "@/api/task";
export default {
  data() {
    return {
      activeName: 'first',
       taskState:10031,
       taskName: '',
       list:[
            // {stattime:"2020年4月12日~2021年4月12日",
            // biaoz:"3000/月",
            // hangye:"所属行业 软件开发（注册个体工商户时的末级分类）",
            // data:"哈哈哈哈哈哈哈哈哈哈哈哈哈法",
            // signedNum:10,
            // notSignedNum:30,
            // blacklistNum:60,
            // settledAmount:20,
            // deductedServiceAmount:300,
            // settledTimes:3,
            // },
            //  {stattime:"2020年4月12日~2021年4月12日",
            // biaoz:"3000/月",
            // signedNum:20,
            // hangye:"所属行业 软件开发（注册个体工商户时的末级分类）",
            // data:"哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈法",
            //  signedNum:50,
            // notSignedNum:60,
            // blacklistNum:90,
            // settledAmount:40,
            // deductedServiceAmount:100,
            // settledTimes:5,
            // },
            //  {stattime:"2020年4月12日~2021年4月12日",
            //  signedNum:30,
            // biaoz:"3000/月",
            // hangye:"所属行业 软件开发（注册个体工商户时的末级分类）",
            // data:"哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈法"
            // },
            //  {stattime:"2020年4月12日~2021年4月12日",
            //  signedNum:40,
            // biaoz:"3000/月",
            // hangye:"所属行业 软件开发（注册个体工商户时的末级分类）",
            // data:"哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈法"
            // },
            //  {stattime:"2020年4月12日~2021年4月12日",
            //  signedNum:50,
            // biaoz:"3000/月",
            // hangye:"所属行业 软件开发（注册个体工商户时的末级分类）",
            // data:"哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈法"
            // }
        ],
        pro:[
          //  {num:20,index:0,customColor:"#169bd5",mat:"200",completeNum:20,title:"已签约人数"},
          //  {num:40,index:1,customColor:"#0f6589",mat:"200",completeNum:55,title:"已结算金额"},
          //  {num:10,index:2,customColor:"#fded3c",mat:"200",completeNum:55,title:"待签约人数"},
          //  {num:40,index:3,customColor:"#3bbf2c",mat:"200",completeNum:55,title:"已扣除服务费"},
          //  {num:40,index:4,customColor:"#e35858",mat:"200",completeNum:55,title:"黑名单人数"},
          //  {num:40,index:5,customColor:"#ffbd9a",mat:"200",completeNum:55,title:"已结算次数"},
        ],
         pageSize: 10,
          totalCount: 0,//数据长度
          currPage: 1,
    };
  },
  created(){
    this.getDataList()
  },
  methods: {
  
  // 自定义进度条文字
  setItemText(row,b) {
    if (row==1){
      return () => {
          return  "已签约人数"+ '  ' + b+"人"
      }
     }
      if (row==2){
      return () => {
          return  "已结算金额"+ '  ' + b+"元"
      }
     }
      if (row==3){
      return () => {
          return  "待签约人数"+ '  ' + b+"人"
      }
     }
      if (row==4){
      return () => {
          return  "已扣除服务费"+ '  ' + b+"元"
      }
     }
      if (row==5){
      return () => {
          return  "黑名单人数"+ '  ' + b+"人"
      }
     }
      if (row==6){
      return () => {
          return  "已结算次数"+ '  ' + b+"次"
      }
     }

  },
  getDataList(a){
    // return
    if (a) {  //判断是不是搜索
          this.currPage=1 
          this.pageSize=10
        }
     merchantTaskPageList({
       "taskState": this.taskState,
        "taskName": this.taskName,
        "current": this.currPage,
        "size": this.pageSize,
     }).then((data=>{
            if (data.code==200) {
            this.list=data.data.records
            this.totalCount=data.data.total
            this.list.map((it)=>{
              this.pro.map(em=>{
                  em.num= it.signedNum
                })
              })
           }
     }))
  },

   // 每也数
      sizeChangeHandle(val) {
        this.pageSize = val;
        this.currPage = 1;
        this.getDataList();
      },
      // 当前页
      currentChangeHandle(val) {
        this.currPage = val;
        this.getDataList();
      },
      // 任务管理
      management(a){
        this.$router.push({path:'/task/management',query:{taskId:a}});
      },
      // 任务结算
      task(a){
       this.$router.push({path:'/task/taskSettlement',query:{taskId:a}});
      },
      // tab
     handleClick(tab, event) {
       this.currPage=1
       this.pageSize=10
        if (tab.index==0)  this.taskState=10031
        if (tab.index==1)  this.taskState=""
        if (tab.index==2)  this.taskState=10030
        if (tab.index==3)  this.taskState=10032
          this.getDataList() 
      },
    }
};
</script>

<style  scoped>
.box-card{
  margin-top: 10px;
  position: relative;
}
.mini{
      display: inline-block;
      height: 20px;
      line-height: 20px;
      text-align: center;
      width:60px;
      border: 1px solid #9e99b9;
      border-radius: 25px ;
      margin-left:14px;
      transform: translateY(-2px);
      font-size: 10px;
  }
  .listspan{
      margin-left: 30px;
  }
  .percalss{
    display: inline-block;
    height: 20px;
    width:45%;
     margin-top: 6px;
  }
.content-view {
	  margin-top: 20px;
	}
.content-view  /deep/.el-progress-bar{
   width: 80%;
}
.content-view  /deep/ .el-progress__text{
 font-size: 10px !important;
}
.hang{
  width: 100%;
  display: inline-block;
}
.percalss:nth-child(2n){
 margin-left: 8%;
}
.but .buton{
  width: 80px;
  margin-left: 0px;
  border-radius: 0px;
}
.but button input{
 float: left;
}
.two{
      font-size: 13px;
      margin-top: 10px;
  }
.position{
  position: absolute;
  right:50px;
  top:20px
}
div /deep/.el-tabs__item:hover {
    /* color:#ffff; */
    background-color: #f2f2f2;
}
div /deep/.el-tabs__item.is-active {
    color:#ffff;
    background-color: #9e99b9;
}
div /deep/.el-tabs__item{
  width:100px !important;
  text-align: center;
}
</style>


